import React, { Component } from "react"

import { BUSY, WAITING } from "../constants/status"

/* Fields */
const DEFAULT_TIME = 180
const COLORS = ['#f44336', '#ff9800', '#ffeb3b', '#78d600']


/* Component Timing */
class Timing extends Component {

    constructor() {
        super()

        this.state = {
            counter: DEFAULT_TIME
        }

        this._timer = 0
    }


    componentDidMount = () => {
        this.start()
    }

    componentWillUnmount = () => {
        clearInterval(this._timer)
    }

    /* Start Timing */
    start = () => {
        if (this._timer === 0 && this.state.counter > 0) {
            this._timer = setInterval(this.countDown, 1000)
        }
    }

    /* Count Down action */
    countDown = () => {

        const { counter } = this.state
        const { status, isPaused } = this.props

        const second = counter - 1

        if ((status !== BUSY && status !== WAITING && !isPaused)) {

            this.setState({ counter: second })

            if (second === 0) {
                clearInterval(this._timer)
            }

        }
    }

    /* Reset Counter */
    reset = counter => {
        this.setState({ counter })
    }


    /* Get color */
    getColor = () => {

        const { counter } = this.state
        const size = COLORS.length

        const index = Math.floor(counter / DEFAULT_TIME * size)

        if (COLORS[index]) {
            return COLORS[index]
        }

        return COLORS[size - 1]
    }



    render = () => {

        const { counter } = this.state
        const color = this.getColor()

        return (
            <div className={`top-timing-box ${counter <= 10 ? 'top-ending-timing-box' : ''}`}>
                <div className={`top-timing ${counter <= 10 ? 'top-ending-timing' : ''}`} style={{ width: `calc(100% * ${counter / DEFAULT_TIME})`, background: color }} />
                <div className="top-timing-text">
                    Примите ваше решение: {counter}
                </div>
            </div>
        )
    }
}

export default Timing
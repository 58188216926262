import React, { Component } from "react"

/* Constants */
import { env } from "../constants"

/* Components */
import { Picture } from "@alicorn/poker-ui"




/* Widget Preloader */
class Preloader extends Component {

    render = () => {

        const { url } = this.props

        const gameImg = url ? url : `${env.mediapoint}/images/back-casino.webp`

        return (
            <div className="preloader">
                <Picture className="full-image" src={gameImg} alt="backgrounf" />
                <div className="preloader-logo">
                    <Picture src={`/images/logo.png`} alt="Preloader" />
                    <div className="preloader-logo-text">
                        <div className="vip">VIP ROOM</div>
                        <p>QUEEN OF</p>
                        <span>SPADES</span>
                    </div>
                </div>
                <div className="preloader-indicator">
                    <Picture className="line" src={`${env.mediapoint}/images/preloader/preloader.svg`} alt="Preloader" />
                    <Picture className="star" src={`${env.mediapoint}/images/preloader/star.svg`} alt="Preloader" />
                </div>
                <p className="support">При поддержке</p>
                <Picture className="makao" src={`${env.mediapoint}/images/preloader/makao.svg`} alt="Preloader" />
            </div>
        )
    }

}

export default Preloader